<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- banner -->
        <div class="banner">
          <el-carousel :interval="5000" arrow="always" :height="autoHeight">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img :style="{cursor:item.link?'pointer':'initial'}" style="width:100%;height:auto;" @click="clickBanner(item.link)" :src="item.imgUrl" alt="">
            </el-carousel-item>
          </el-carousel>
            
        </div>
        <!-- 面包屑 -->
        <div class="bread">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/">首页</a></el-breadcrumb-item>
            <el-breadcrumb-item class="p_bread"><a href="https://www.wch.cn/application.html">应用方案</a></el-breadcrumb-item>
            <el-breadcrumb-item>{{content.categoriesList&&content.categoriesList[0].name?content.categoriesList[0].name:''}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{content.title}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 内容 -->
        <div class="main" v-loading="loading">
            <el-row :gutter="0" style="width:100%">
                <el-col :xs="24" :sm="24" :md="6" :lg="5" :xl="4" style="padding:0px;">
                    <side-bar ref="sideBar" :style="{width:isMoPhone?'100%':'93%', marginBottom:isMoPhone?'15px':'0px'}"  
                        :asideList="asideList" :activeMenu="activeMenu" @currentAside = currentAside>
                    </side-bar>
                </el-col>
                <el-col :xs="24" :sm="24" :md="18" :lg="19" :xl="20" style="padding:30px;background:#fff;" >
                    <article-com ref="articleCom" ></article-com>
                </el-col>
            </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '@/pages/topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import sideBar from '@/pages/components/sidebar'
import articleCom from '@/pages/components/article'
import { applicationDetail } from '@/services/application.js'
export default {
  name: "applicationDetail",
  components:{
    topBar,
    footerPart,
    sideBar,
    articleCom,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        activeMenu:{key:'1', asideName:'应用方案', path:'/application.html'},
        asideList:[
          {
            asideName:'',
            childList:[
              {key:'1', asideName:'应用方案', path:'/application.html'},
            ]
          }
        ],
        bannerList:[
            {imgUrl:require('@/assets/image/application/banner1.png'), link:'https://www.wch.cn/application/660.html'},
            {imgUrl:require('@/assets/image/application/banner2.png'), link:'https://www.wch.cn/application/725.html'},
            {imgUrl:require('@/assets/image/application/banner3.jpeg'), link:'https://www.wch.cn/application.html'},
            {imgUrl:require('@/assets/image/application/banner4.jpeg'), link:'https://www.wch.cn/application/506.html'},
        ],
        content:{},
        autoHeight:'',
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted')
    if (this.windowWidth < 992) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }
    this.getAutoHeight()
    this.getApplicationDetail()
  },
  activated(){
    console.log('activated')
    this.getAutoHeight()
    this.getApplicationDetail()
   
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
      this.getAutoHeight()
    },
    getAutoHeight(){
      let _w = document.documentElement.clientWidth || document.body.clientWidth; 
      this.autoHeight = _w * 300 / 1920 + 'px'; //banner图在1920像素设计稿下是300px高度
    },
    //点击banner
    clickBanner(link){
        if(link){
            window.open(link)
        }
    },
    //点击侧边栏
    currentAside(item){
      console.log('点击的menu',item)
      this.activeMenu = item
      document.title = this.activeMenu.asideName+' - 南京沁恒微电子股份有限公司'
      this.$router.push({ path: item.path,})
    },
    //获取方案详情
    getApplicationDetail(){
        let id = this.$route.params.applicationId //id
        this.content = {}
        this.loading = true;
        applicationDetail({id}).then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                console.log('content---',this.content)
                document.title = this.content.title+' - 南京沁恒微电子股份有限公司'
                this.$refs.articleCom.init( this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    
  }
};
</script>
<style scoped lang="less">
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .bread{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    font-size: 18px;
 }
 .p_bread .el-breadcrumb__inner a, /deep/.el-breadcrumb__inner.is-link{
    color: #337ab7;
 }
 .banner{
    width: 100%;
    // .el-carousel__container{
    //    height:300px
    // }
    // .bannerImg{
    //   width:100%; 
    //   height:300px
    // }
 }
 .main {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
    // box-shadow: 6px 6px 12px #0000001a;
    // background: #fff;
    border-radius: 4px;
    text-align: left;
   
 }

@media screen and (max-width: 1400px) {
  
}
@media screen and (max-width: 1100px) {
   
}
@media screen and (max-width: 800px) {
   
}
@media screen and (max-width: 500px) {
  
}
</style>